@use '../../styles/mixins' as mixins;
@use '../../styles/breakpoints' as bp;

.case-study-grid {

	@include mixins.grid(400px);

	.case-study {
		background-color: var(--wp--preset--color--bnpp-green-tint-10);
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		position: relative;

		&::after {
			content: "";
			position: absolute;
			inset: 0;
			opacity: 0;
			border: solid 4px var(--wp--preset--color--bnpp-green);
			transition: 300ms;
		}

		@media screen and (max-width: bp.$breakpoint-small) {
			flex-direction: column;
			flex-wrap: nowrap;
		}

		&:hover {

			@include mixins.hoverAnimationsStart;

			&::after {
				opacity: 1;
			}

		}

		.case-study-content {
			transition: 300ms;
			flex: 1 1 200px;
			padding: var(--wp--preset--spacing--60);
			padding-right: 0;
			display: flex;
			flex-direction: column;
			height: 100%;

			@media screen and (max-width: bp.$breakpoint-small) {
				flex: 1;
				width: 100%;
			}

			h3 {
				color: var(--wp--preset--color--bnpp-black);
				font-size: var(--wp--preset--font-size--heading-xs);
				text-transform: uppercase;
				margin: 0;
			}

			.tags {
				display: flex;
				flex-wrap: wrap;
				gap: var(--wp--preset--spacing--20);

				.tag {
					background-color: var(--wp--preset--color--bnpp-green-tint-10);
					padding: 0 var(--wp--preset--spacing--20);
					font-size: var(--wp--preset--font-size--body-s);
					display: flex;
					gap: var(--wp--preset--spacing--20);
					align-items: center;
					color: var(--wp--preset--color--bnpp-black);

					span {
						display: block;
						white-space: nowrap;
					}
					
					img {
						max-width: 35px;
						width: 100%;
					}
				}

				.asset-icon {
					width: 35px;
					display: grid;
				}
			}
		}

		.icon {
			flex: 0 1 200px;
			position: relative;
			height: 100%;
    		display: flex;

			@media screen and (max-width: bp.$breakpoint-small) {

				position: static;
				
				svg {
					margin: 0 auto;
					max-width: 200px;
					width: 100%;
				}

			}

			// &::after {
			// 	content: "";
			// 	position: absolute;
			// 	top: 0;
			// 	left: 0;
			// 	height: 100%;
			// 	width: 1px;
			// 	background: var(--wp--preset--color--bnpp-black);

			// 	@media screen and (max-width: bp.$breakpoint-small) {
			// 		height: 1px;
			// 		width: 100%;
			// 		bottom: min(100%, 200px);
			// 	}
			// }

			& {
				@include mixins.hoverKeyframes;
			}

		}
		
	}

	&.dark {


		.case-study {
			border: solid 1px var(--wp--preset--color--white);

			.case-study-content  {

				@media screen and (max-width: bp.$breakpoint-small) {
					&:has(+.icon) {
						border-bottom: solid 1px var(--wp--preset--color--white);
					}
				}

				h3 {
					color: var(--wp--preset--color--white);
				}

				.learn-more {
					color: #7DCBBA;
				}
			}
		}

		.icon {
			border-left: solid 1px var(--wp--preset--color--white);

			@media screen and (max-width: bp.$breakpoint-small) {
				border-left: none;
			}
		}

	}

}